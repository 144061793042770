<template>
  <div class="assignment">
    <div class="column" style="min-height:calc(100vh - 175px); margin-bottom: 0;">
      <div>
        <el-table
          ref="mainTable"
          :key="modelFreq+'-'+$root.docWidth"
          :height="$root.docHeight-175"
          size="medium"
          v-loading="divisors.loading"
         :data="divisors.items">
          <div slot="empty"><el-empty :image-size="150"></el-empty></div>
          <el-table-column
            min-width="200"
            fixed="left"
            :show-overflow-tooltip="false"
            label="因子名称">
            <template slot-scope="props">
              <el-popover placement="right-start" width="350" trigger="hover">
                <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">
                  <div v-for="(item,index) in props.row.children" :key="index" :class="{'padding-top-10':index>0,'margin-top-10':index>0,'border-top':index>0}">
                    <div><span class="color-sub">数据名称：</span>{{item.factorName}}</div>
                    <div><span class="color-sub">数据单位：</span>{{item.unit}}</div>
                    <div><span class="color-sub">数据类型：</span>{{item.typeName}}</div>
                    <div><span class="color-sub">更新频率：</span>{{item.modelFreq}}</div>
                    <div><span class="color-sub">统计日期：</span>{{item.startDate}}-{{item.endDate}}</div>
                    <div><span class="color-sub">方法论：</span>{{item.func}}</div>
                    <div><span class="color-sub">数据来源：</span>{{item.dataSource}}</div>
                  </div>
                </div>
                <span slot="reference" class="display-inline-block" @click="getDivisorChart(props.row)">
                  <span class="text-overflow-ellipsis-block">
                    <span class="custom-tag margin-right-10" color="red" size="mini" v-if="props.row.isOut">外生</span>
                    <span class="link-text">{{props.row.divisorName}}</span>
                  </span>
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="单位" prop="unit">
            <template slot-scope="props">{{ props.row.unit||"-" }}</template>
          </el-table-column>
          <el-table-column v-for="(item,index) in divisors.dataList" :key="index" :label="item.dateCycleShow" min-width="110">
            <template slot-scope="props">
              <div class="flex-row align-items-center">
                <span v-if="index>0&&item[props.row.id].valueType!=1&&numWarning(divisors.dataList[index-1][props.row.id].value||props.row.hisValue,item[props.row.id].value,props.row.hisRide)" class="dot margin-right-5"></span>
                <el-tooltip :disabled="!(index>0&&item[props.row.id].valueType!=1&&numWarning(divisors.dataList[index-1][props.row.id].value||props.row.hisValue,item[props.row.id].value,props.row.hisRide))" content="较前值的涨跌幅超过10%" placement="top">
                  <span :class="{'color-sub':item[props.row.id].valueType==1}">{{ item[props.row.id].value | numFormat(2,'-')}}</span>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="赋值说明"
            :show-overflow-tooltip="true"
            prop="reason"
            min-width="200"
            fixed="right"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            prop="reason"
            width="50"
            fixed="right"
          >
            <template slot-scope="props">
             <span class="link-text text-nowrap" @click="getDivisorAdjustInfo(props.row)">调整</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      
      
    </div>
    <!-- 编辑因子弹窗 -->
    <el-dialog custom-class="custom-dialog" :title="divisorActive.divisorName+'-赋值调整'" append-to-body :close-on-click-modal="false" width="50%" top="10vh" :visible.sync="divisorAdjustInfo.visible">
      <div style="height: calc(80vh - 180px); overflow: auto; padding: 0 10px;">
        <el-form :model="divisorAdjustInfo.form" ref="divisorForm" label-width="120px" class="flex-row flex-wrap" :key="divisorAdjustInfo.visible">
          <div v-for="(item,index) in divisorAdjustInfo.form.dataList" :key="index" style="width:50%;">
           
              <el-form-item v-if="item.valueType==1" :label="item.dateCycleShow" :prop="'dataList.'+index+'.hisValue'"
                :rules="[
                    { validator: validateNumber, trigger: ['blur','change'] }
                  ]"
                >
                  <el-input maxlength="10" v-model.trim="item.hisValue" autocomplete="off" placeholder="" :disabled="item.valueType==1"></el-input>
              </el-form-item>
              <el-popover
                  v-else
                  popper-class="opacity-80"
                  placement="top-end"
                  width="200"
                  offset="30"
                  trigger="hover"
              >
                <div>
                  <div>卓创预测：{{item.sciValue | numFormat(2,'-')}}</div>
                </div>
                <el-form-item slot="reference" :label="item.dateCycleShow" :prop="'dataList.'+index+'.befarValue'" 
                :rules="modelFreq=='week'&&divisorAdjustInfo.form.dataList.slice(index+1,divisorAdjustInfo.form.dataList.length).some(v=>{return v.befarValue})?[
                { required: true, message: '数值不能为空！', trigger: 'change' },
                { validator: validateNumber, trigger: ['blur','change'] }
              ]:[ { validator: validateNumber, trigger: ['blur','change'] }]">
                  <el-input maxlength="10" v-model.trim="item.befarValue" placeholder="" autocomplete="off"></el-input>
                </el-form-item>
              </el-popover>
            
          </div>
          <el-form-item prop="reason" label="赋值说明" class="flex-auto" style="width:100%">
            <el-input maxlength="200"  show-word-limit v-model.trim="divisorAdjustInfo.form.reason" type="textarea" :rows="4" placeholder="请输入对价格影响的逻辑传导关系进行简要说明" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div>预测方法建议：{{ divisorAdjustInfo.form.suggestion }}</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="flex-row justify-center">
          <el-button @click="divisorAdjustInfo.visible=false">取消</el-button>
          <el-button type="primary" @click="submitDivisorAdjustForm('divisorForm')" :loading="divisorAdjustInfo.loading">保 存</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 因子数据图详情弹窗 -->
    <el-dialog custom-class="custom-dialog" :title="divisorActive.divisorName" append-to-body :close-on-click-modal="false" width="80%" top="15vh" :visible.sync="divisorData.visible">
      <div class="flex-column" style="height: calc(70vh - 80px);" v-loading="divisorData.loading">
        <div><span>卓创赋值说明：</span>{{divisorData.info.sciReason}}</div>
        <div class="margin-top-5"><span>滨化赋值说明：</span>{{divisorData.info.befarReason}}</div>
        <Chart :option="divisorChart" class="flex-auto"></Chart>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Chart from "@/components/Chart";
export default {
  name: 'Assignment',
  components:{
    Chart,
  },
  props:{
   
  },
  data (){
    return{
      modelFreq:this.$route.params.modelFreq,

      divisors:{//因子赋值列表
        loading:false,
        items:[],
        dataList:[],
      },

      divisorActive:{},//当前编辑的因子
      divisorAdjustInfo:{//当前编辑的因子调整详情
        loading:false,
        visible:false,
        form:{}
      },

      divisorData:{//因子图表详情
        loading:false,
        visible:false,
        info:{}
      },

      validateNumber:(rule, value, callback) => {
        //表单数字校验规则
        if (isNaN(value)) {
          callback(new Error('请输入数值类型！'));
        } else {
          callback();
        }
      },
    }
     
  },
  beforeCreate(){
    if(!['week','month'].some(val=>{return val==this.$route.params.modelFreq})){
      this.$router.replace({
          path: '/404',
      })
    }
  },
  created(){
    this.getDivisors()
  },
  mounted(){
    
  },
  methods:{

    //因子赋值=========================================================================
    //获取因子赋值列表
    getDivisors(){
      this.divisors.loading=true;
      this.$instance.get('/assignment/getBefarAssignmentOutValue',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq
          },
      })
      .then(res=>{
        this.divisors.loading=false;
        if(res.data.code==0){
          this.divisors.items=res.data.info.items||[]
          this.divisors.dataList=res.data.info.dataList||[]
          this.$nextTick(()=>{
            this.$refs.mainTable.doLayout()
          })
        }else{
          this.divisors.items=[]
          this.divisors.dataList=[]
        }
      })
      .catch(()=>{
        this.divisors.loading=false;
        this.divisors.items=[]
        this.divisors.dataList=[]
      })
    },
     //获取因子赋值调整详情
     getDivisorAdjustInfo(divisor){
      this.divisorActive=divisor
      this.divisors.loading=true;
      this.$instance.get('/assignment/getOutAdjustInfo',{
          params: {
            ...this.$store.state.basicParams,
            modelFreq:this.modelFreq,
            divisorId:divisor.divisorId,
            dataId:divisor.id,
          },
      })
      .then(res=>{
        this.divisors.loading=false;
        if(res.data.code==0){
          this.divisorAdjustInfo.form=res.data.info;
          this.divisorAdjustInfo.visible=true;
        }
      })
      .catch(()=> {
        this.divisors.loading=false;
      }); 
    },
    saveDivisorAdjustInfo(){
      this.divisorAdjustInfo.loading=true
      let requestData={
        ...this.$store.state.basicParams,
        modelFreq:this.modelFreq,
        dataId:this.divisorAdjustInfo.form.dataId,
        reason:this.divisorAdjustInfo.form.reason,
        dataList:this.divisorAdjustInfo.form.dataList.map(v=>{return {
          "month": v.dateCycle,
          "valueType": v.valueType,
          "value": v.valueType==1?v.hisValue:v.befarValue
        }}),
      }
      this.$instance.post('/assignment/saveBefarOutExpertValue',requestData)
      .then(res=>{
        this.divisorAdjustInfo.loading=false
        if(res.data.code==0){
          this.divisorAdjustInfo.form.dataList.forEach((v,index)=>{
            this.divisors.dataList[this.divisors.dataList.length-this.divisorAdjustInfo.form.dataList.length+index][this.divisorActive.id]={valueType:v.valueType,value:v.valueType==1?v.hisValue:v.befarValue}
          })
          this.divisorActive.reason=this.divisorAdjustInfo.form.reason
          this.divisorAdjustInfo.visible=false
        }
      })
      .catch(()=>{
        this.divisorAdjustInfo.loading=false
      })
    },
    submitDivisorAdjustForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
           this.saveDivisorAdjustInfo();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //获取因子走势图详情
    getDivisorChart(divisor){
      this.divisorActive=divisor
      this.divisorData={
        loading:true,
        visible:true,
        info:{}
      }
      this.$instance.get('/assignment/getDivisorDetail',{
          params: {
            ...this.$store.state.basicParams,
            productId:divisor.productId,
            modelFreq:this.modelFreq,
            divisorId:divisor.divisorId,
            isOut:divisor.isOut,
          },
      })
      .then(res=>{
        this.divisorData.loading=false;
        if(res.data.code==0){
          this.divisorData.info=res.data.info;
        }else{
          this.divisorData.info={}
        }
      })
      .catch(()=> {
        this.divisorData.loading=false;
        this.divisorData.info={}
      }); 
    },
    
    
  },
  computed:{
    //赋值比较是否异常-提示
    numWarning(){
      return (a,b,c)=>{
        if(a&&this.$numFormat(b)){
          return Math.abs(b/a-1) > c?true:false
        }else{
         return false
        }
      }
    },
    //因子详情走势图
    divisorChart(){
      let chartData=this.divisorData.info
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.yAxis.name=this.divisorActive.unit
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dateCycleShow})
        // opt.dataZoom.startValue=opt.xAxis.data.length-15
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            unit:this.divisorActive.unit,
            itemStyle:{
                opacity:0
            }
          }
        })
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            id:v.id,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id=='hisValue'?"solid":"dashed",
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              let item=chartData.items[val.seriesIndex]
              if(item.id!='hisValue'){
                if(!this.$numFormat(chartData.dataList[val.dataIndex]['hisValue'])||!params.some(v=>v.seriesId=='hisValue')){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
              }else{
                str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
              }
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    }
    
  },
  watch: {
    "$route.params.modelFreq" : {
     handler:function(val){
       this.modelFreq=val
       this.getDivisors()
     },
     deep:true
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>
 .dot{
  width:5px;
  height: 5px;
  border-radius: 100%;
  background: #F49300;
}
</style>
<style  lang="scss">

</style>
